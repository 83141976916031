export const sizeFilterOptions: {value: string, text: string} [] = [
  { value: '', text: 'Size' },
  { value: '16', text: '16x16' },
  { value: '32', text: '32x32' },
  { value: '64', text: '64x64' },
  { value: '128', text: '128x128' },
];

export const sortFilterOptions: {value: string, text: string} [] = [
  { value: '', text: 'Filter by' },
  { value: 'a', text: 'Price (low to high)' },
  { value: 'b', text: 'Price (high to low)' },
  { value: 'c', text: 'Size (low to high)' },
  { value: 'd', text: 'Size (high to low)' },
];

export const sortFilterOptionsPriceOnly: {value: string, text: string} [] = [
  { value: '', text: 'Filter by' },
  { value: 'a', text: 'Price (low to high)' },
  { value: 'b', text: 'Price (high to low)' },
];

export const regionOptions: {value: string, text: string} [] = [
  { text: 'Asia', value: 'Asia' },
  { text: 'North', value: 'North' },
  { text: 'East', value: 'East' },
  { text: 'Forest', value: 'Forest' },
  { text: 'Farm', value: 'Farm' },
];
